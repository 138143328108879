// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Cfshopthroughaudiovideocallwithstore from "../../blocks/cfshopthroughaudiovideocallwithstore/src/Cfshopthroughaudiovideocallwithstore";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Cfretailerbuyfromsupplier from "../../blocks/cfretailerbuyfromsupplier/src/Cfretailerbuyfromsupplier";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import Cfstoredetails from "../../blocks/cfstoredetails/src/Cfstoredetails";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Reservations from "../../blocks/reservations/src/Reservations";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import Cfcustomordermanagement from "../../blocks/cfcustomordermanagement/src/Cfcustomordermanagement";
import Cfsupplierpayment from "../../blocks/cfsupplierpayment/src/Cfsupplierpayment";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Cfhighlightmystoreretailer from "../../blocks/cfhighlightmystoreretailer/src/Cfhighlightmystoreretailer";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Maps from "../../blocks/maps/src/Maps";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Location from "../../blocks/location/src/Location";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import UserProfileAdvance from "../../blocks/user-profile-advanced/src/UserProfileAdvance";
import InventoryManagement from "../../blocks/inventorymanagement/src/InventoryManagement";
import Couponcodegenerator from "../../blocks/couponcodegenerator/src/Couponcodegenerator";
import Couponcodetable from "../../blocks/couponcodegenerator/src/Couponcodetable";
import Cfcashondeliverybalancesheetmanagementdeliveryagent from "../../blocks/cfcashondeliverybalancesheetmanagementdeliveryagent/src/Cfcashondeliverybalancesheetmanagementdeliveryagent";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import AddressManagement from "../../blocks/addressmanagement/src/AddressManagement";
import AddAddress from "../../blocks/addressmanagement/src/AddAddress";
import Cfretailercanshowsupplierinventory from "../../blocks/cfretailercanshowsupplierinventory/src/Cfretailercanshowsupplierinventory";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import Cfexistingcustomerverification from "../../blocks/cfexistingcustomerverification/src/Cfexistingcustomerverification";
import Search from "../../blocks/search/src/Search";
import Notifications from "../../blocks/notifications/src/Notifications";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";


const routeMap = {
Cfshopthroughaudiovideocallwithstore:{
 component:Cfshopthroughaudiovideocallwithstore,
path:"/Cfshopthroughaudiovideocallwithstore"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Cfretailerbuyfromsupplier:{
 component:Cfretailerbuyfromsupplier,
path:"/Cfretailerbuyfromsupplier"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
Cfstoredetails:{
 component:Cfstoredetails,
path:"/Cfstoredetails"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Reservations:{
 component:Reservations,
path:"/Reservations"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
Cfcustomordermanagement:{
 component:Cfcustomordermanagement,
path:"/Cfcustomordermanagement"},
Cfsupplierpayment:{
 component:Cfsupplierpayment,
path:"/Cfsupplierpayment"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Cfhighlightmystoreretailer:{
 component:Cfhighlightmystoreretailer,
path:"/Cfhighlightmystoreretailer"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
GroupVideoCall:{
 component:GroupVideoCall,
path:"/GroupVideoCall"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Maps:{
 component:Maps,
path:"/Maps"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Location:{
 component:Location,
path:"/Location"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
UserProfileAdvance:{
 component:UserProfileAdvance,
path:"/UserProfileAdvance"},
InventoryManagement:{
 component:InventoryManagement,
path:"/InventoryManagement"},
Couponcodegenerator:{
 component:Couponcodegenerator,
path:"/Couponcodegenerator"},
Couponcodetable:{
 component:Couponcodetable,
path:"/Couponcodetable"},
Cfcashondeliverybalancesheetmanagementdeliveryagent:{
 component:Cfcashondeliverybalancesheetmanagementdeliveryagent,
path:"/Cfcashondeliverybalancesheetmanagementdeliveryagent"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling2"},
AddressManagement:{
 component:AddressManagement,
path:"/AddressManagement"},
AddAddress:{
 component:AddAddress,
path:"/AddAddress"},
Cfretailercanshowsupplierinventory:{
 component:Cfretailercanshowsupplierinventory,
path:"/Cfretailercanshowsupplierinventory"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
Chat:{
 component:Chat,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
Cfexistingcustomerverification:{
 component:Cfexistingcustomerverification,
path:"/Cfexistingcustomerverification"},
Search:{
 component:Search,
path:"/Search"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;